const imgUrl = "../assets/images/";
const opload = {
  fullscreen: true,
  background: "rgba(0, 0, 0, 0.7)",
  text: "拼命加载中..."
};
import axios from "axios";
import $qs from "qs";
import router from "../router/index.js";
import store from "../store/index.js";
import { Message, Loading } from "element-ui";
function conver(s) {
  return s < 10 ? "0" + s : s;
}
export default {
  imgUrl,
  opload,
  post(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, $qs.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accesstoken: sessionStorage.getItem("token")
          }
        })
        .then(response => {
          if (response.data.code == 400) {
            setTimeout(() => {
              Message({
                message: response.data.msg,
                type: "error",
                duration: 1500
              });
              sessionStorage.clear();
              Loading.service().close();
              router.replace({ name: "login" });
            }, 200);
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
          headers: {
            accesstoken: sessionStorage.getItem("token")
          }
        })
        .then(response => {
          if (response.data.code == 400) {
            setTimeout(() => {
              Message({
                message: response.data.msg,
                type: "error",
                duration: 1500
              });
              sessionStorage.clear();
              Loading.service().close();
              router.replace({ name: "login" });
            }, 200);
          } else {
            resolve(response.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //判断手机 pc
  _isMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  },
  // 标准时间转换
  datatime(data = {}) {
    var d = new Date(data);
    var datetime =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getDate() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds();
    return datetime;
  },
  // 转化时间戳
  datatamp(time) {
    let date = new Date(time);
    return date.getTime();
  },
  // 时间戳转化为时间
  timestampToTime(timestamp) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D = date.getDate() + " ";
    var h = date.getHours() + ":";
    var m = date.getMinutes() + ":";
    var s = date.getSeconds();
    return Y + M + D + h + m + s;
  },
  // 获取当前时间
  getnow() {
    var myDate = new Date();
    //获取当前年
    var year = myDate.getFullYear();
    //获取当前月
    var month = myDate.getMonth() + 1;
    //获取当前日
    var date = myDate.getDate();
    var h = myDate.getHours(); //获取当前小时数(0-23)
    var m = myDate.getMinutes(); //获取当前分钟数(0-59)
    var s = myDate.getSeconds();
    //获取当前时间
    return (
      year +
      "-" +
      conver(month) +
      "-" +
      conver(date) +
      " " +
      conver(h) +
      ":" +
      conver(m) +
      ":" +
      conver(s)
    );
  },
  // 转化为国际日期格式
  intertime(time) {
    return new Date(time.replace(/-/g, "/").replace("T", " ")).toISOString();
  },
  // 退出
  usergetout(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, $qs.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accesstoken: sessionStorage.getItem("token")
          }
        })
        .then(
          response => {
            resolve(response.data);
            sessionStorage.clear();
          },
          err => {
            reject(err);
            router.replace({ name: "login" });
          }
        );
    });
  },
  // 获取未分配以及分配数据
  fenpei(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, $qs.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // 'Content-Type':'application/json',
            accesstoken: sessionStorage.getItem("token")
          }
        })
        .then(response => {
          if (response.data.code == 400) {
            setTimeout(() => {
              Message({
                message: response.data.msg,
                type: "error",
                duration: 1500
              });
              sessionStorage.clear();
              Loading.service().close();
              router.replace({ name: "login" });
              return false;
            }, 200);
          } else {
            resolve(response.data);
            store.commit("setCount", response.data.data.count1);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取高度
  gaodu() {
    return document.documentElement.clientHeight || document.body.clientHeight;
  },
  // 宽度
  kuandu() {
    return document.documentElement.clientWidth || document.body.clientWidth;
  },
  // 取消单选
  cancleradio(kind, e, zhi) {
    let huishu = zhi;
    e === huishu ? (huishu = "") : (huishu = e);
    return huishu;
  },
  // 任务处理跳转详情
  godetail(idx) {
    let idx2 = {};
    idx2.order_id = idx.order_id;
    idx2.order_no = idx.order_no;
    idx2.cc_id = idx.cc_id;
    idx2.TaskType = idx.TaskType;
    let newpage = router.resolve({
      name: "detailes",
      query: { idx: JSON.stringify(idx2) }
    });
    window.open(newpage.href, "_blank");
  }
};
