import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: '益鸟维修CC系统-登录'
    },
    component: Login
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: '益鸟维修CC系统-首页',
      requireAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue")
  },
  {
    path: "/detailes",
    name: "detailes",
    meta: {
      title: '益鸟维修CC系统-详情',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/detailes.vue")
  },
  {
    path: "/updatapass",
    name: "updatapass",
    meta: {
      title: '益鸟维修CC系统-修改密码'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/updatapass.vue")
  },
  {
    path: "/mapLocation",
    name: "mapLocation",
    meta: {
      title: '益鸟维修CC系统-查看位置'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/mapLocation.vue")
  },
];

const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
