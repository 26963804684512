import { message } from "@/util/message.js";
import Avue from "@smallwei/avue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import avueCrud from '@smallwei/avue';
import "@/assets/style.css";
import imgPreview from "@/components/imgPreview";
import config from "@/util/config";
import toExcel from "@/util/json2excel.js";
import util from "@/util/util.js";
import "@smallwei/avue/lib/index.css";
import Axios from "axios";
import qs from "qs";
import "video.js/dist/video-js.css";
import JsonExcel from 'vue-json-excel'; //引入excel 插件
import VueJsonp from "vue-jsonp";
import audio from "vue-mobile-audio";
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
Vue.component('downloadExcel', JsonExcel)// 全局注册引入excel 插件
Vue.use(ElementUI);
// Vue.use(avueCrud)
Vue.use(Avue);
Vue.use(VueJsonp);
Vue.use(imgPreview);
Vue.use(VideoPlayer);
Vue.use(audio);

Vue.config.productionTip = false;
Vue.prototype.$toExcel = toExcel;
Vue.prototype.$http = Axios;
Vue.prototype.$qs = qs;
Vue.prototype.util = util;
Vue.prototype.$message = message;
// Vue.prototype.HOST = "/api";
// Vue.prototype.HOST2 = "/ips";
// Vue.prototype.HOST3 = "/mapp";
// Vue.prototype.HOST4 = "/phe";
// Vue.prototype.HOST5 = "/kai";
// Vue.prototype.HOST6 = "/oldman";

// api地址
// Vue.prototype.HOST = "http://c1.ynweixiu.art/api-v1";
Vue.prototype.HOST = config.requestUrl;

Vue.prototype.HOST2 = "https://apis.map.qq.com/ws/place/v1/suggestion/";
Vue.prototype.HOST3 = "https://apis.map.qq.com/ws/geocoder/v1/";
Vue.prototype.HOST4 = "https://a1.7x24cc.com/accessToken";
Vue.prototype.HOST5 = "https://a1.7x24cc.com/commonInte";

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (sessionStorage.getItem("token")) {
      // 通过session获取当前的token是否存在
      next();
    } else {
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // next()
});

// query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
