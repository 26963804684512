<template>
  <div class="login labelColor" :style="{ height: bhei }" v-if="duan">
    <el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm"
      style="margin: 220px auto">
      <el-form-item label="用户名" prop="user" class="mt15 labelColor" style="color: white!">
        <el-input v-model.number="ruleForm2.user" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass" class="mt15 labelColor">
        <el-input type="password" v-model="ruleForm2.pass" auto-complete="off" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code" class="mt15 labelColor">
        <el-input v-model="ruleForm2.code" auto-complete="off" style="width: 260px"></el-input>
        <img class="yanzheng" :src="codeimg" alt @click="changecode" />
      </el-form-item>
      <el-row type="flex" class="row-bg" justify="center" style="margin-top: 40px">
        <el-button type="primary" @click="submitForm('ruleForm2')">立即登录</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import config from '@/util/config'
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm2.checkPass !== '') {
          this.$refs.ruleForm2.validateField('checkPass')
        }
        callback()
      }
    }
    var checkcode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('验证码不能为空'))
      }
      callback()
    }
    return {
      ruleForm2: {
        pass: '',
        user: '',
        code: '',
        duan: false
      },
      rules2: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        user: [{ validator: checkAge, trigger: 'blur' }],
        code: [{ validator: checkcode, trigger: 'blur' }]
      },
      // 验证code
      codeimg: '',
      codekey: '',
      bhei: ''
    }
  },
  created() {
    this.duan = true
    this.getcode()
    this.bhei = this.util.gaodu() + 'px'
    // //console.log(sessionStorage.getItem("token"),'!!!!!!!!!!!!!!123')
    // if (this.util._isMobile()) {
    //   window.location.href = 'http://ccos.yiniaoweb.com/mobile/'
    // } else {
    //   this.duan = true
    //   this.getcode();
    //   this.bhei = this.util.gaodu() + "px";
    // }
    // this.orderNotify.close()
  },
  // computed:{
  //   ...mapState({
  //     orderNotify:state=>state.orderNotify
  //   })
  // },
  methods: {
    getcode() {
      let that = this
      that.$http
        .get(config.captcha)
        .then((res) => {
          that.codeimg = res.data.data.img
          that.codekey = res.data.data.key
          // console.log(that.codekey, "code++++");
        })
        .catch(function (error) {
          // 请求失败处理
          window.console.log(error)
        })
    },
    changecode() {
      this.getcode()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$loading({
            fullscreen: true,
            background: 'rgba(0, 0, 0, 0.7)',
            text: '登录中...'
          })
          let that = this
          // that.HOST="http://cc.ynweixiu.art/api-v1";
          that.util
            .post(that.HOST + '/Login/login', {
              username: that.ruleForm2.user,
              password: that.ruleForm2.pass,
              code: that.ruleForm2.code,
              key: that.codekey
            })
            .then((res) => {
              // console.log("进入");
              // console.log(res, "登录返回+++++");
              if (res.code == 200) {
                sessionStorage.setItem('token', res.data.cc_token)
                sessionStorage.setItem('username', res.data.username)
                sessionStorage.setItem('admin', res.data.admin_id)
                sessionStorage.setItem(
                  'administrators',
                  res.data.administrators
                )
                sessionStorage.setItem('integratedid', res.data.gh)
                sessionStorage.setItem('logo', res.data.logo)
                this.$loading().close()
                this.$router.push({ path: '/home' })
              } else {
                that.$message({
                  showClose: true,
                  type: 'error',
                  duration: 1200,
                  message: res.msg,
                  offset: 90
                })
                this.$loading().close()
              }
            })
        } else {
          window.console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style scoped>
.el-form-item--feedback {
  width: 500px;
  margin: 30px auto;
}

.yanzheng {
  width: 120px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  left: 260px;
}

.el-form-item__content {
  display: flex;
  margin-left: 0 !important;
}

.login {
  /* background: url("../assets/images/bbg.jpg") no-repeat; */
  /* background: url('../assets/images/background.jpg') no-repeat; */
  background: url('https://imgaes.yiniaoweb.com/cc/background.5743a3a5.jpg') no-repeat;
  /* background: url("../assets/images/bg.jpg") no-repeat; */
  /* background-size: 100%; */
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.demo-ruleForm {
  position: absolute;
  left: 35%;
  top: 20%;
  transform: translateY(-60%);
  width: 360px;
  margin-left: -180px;
}

/* .labelColor{
  color:white;
} */
div /deep/ .el-form-item__label {
  color: white !important;
  position: relative;
}
</style>
