import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dialogTableVisible: false,
    order_id: "",
    task: 0,
    cc_id: "",
    order_num: "",
    works: 0,
    llwidth: 0,
    TaskType: -1,
    orderstate: "",
    newOrder: 0,
    pendOrder: 0,
    hangOrder: 0,
    dialogTableVisibleAdd: false,
    dialogFormula: false,
    // orderNotify:"",
    plan_id: "",
    formula_id: "",
    number: "",
    poolToAsign: false,

    form: {},
  },
  mutations: {
    setMessage(state, data) {
      state.dialogTableVisible = data;
    },
    setDialog(state, data) {
      state.dialogTableVisibleAdd = data;
    },
    setFormulaDialog(state, data) {
      state.dialogFormula = data;
    },
    setOrder(state, data) {
      state.order_id = data.order_id;
      state.order_num = data.order_no;
      state.cc_id = data.cc_id;
      state.TaskType = data.TaskType;
      state.orderstate = data.state;
    },
    setPlan(state, data) {
      state.plan_id = data.plan_id;
    },
    setFormula(state, data) {
      state.formula_id = data.id;
    },
    setNumber(state, data) {
      state.number = data;
    },
    settask(state, data) {
      state.task = data;
    },
    setCount(state, data) {
      state.works = data;
    },
    setlwidth(state, data) {
      state.llwidth = data;
    },
    setNewOrder(state, data) {
      state.newOrder = data;
    },
    setPendOrder(state, data) {
      state.pendOrder = data;
    },
    setHangOrder(state, data) {
      state.hangOrder = data;
    },

    // setOrderNotify(state,data){
    //   state.orderNotify = data
    // },
  },
  actions: {},
  modules: {},
});
