<template>
  <el-dialog :visible.sync="isShowImageDialog" @closed="clearImg">
    <el-carousel v-if="imgs.length>0" indicator-position="outside" height="600px">
      <el-carousel-item v-for="src in imgs" :key="src">
        <img :src="src" style="max-width: 100%;max-height: 100%;display: block; margin: 0 auto;" />
      </el-carousel-item>
    </el-carousel>
    <div v-if="vdos.length>0" class="demo">
      <span
        v-if="play"
        class="abs"
        style="right: -31px;
    top: -43px;cursor: pointer;"
        @click="closetv"
      >关闭</span>
      <div v-if="play" class="pos">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>
      <div v-else class="flex_warp">
        <div v-for="url in vdos" :key="url">
          <div class="videolists playContainer mr20" @click="playurl(url)">
            <div class="playBtn">
              <span>start</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
 
<script>
export default {
  name: "ImgPreview",
  data() {
    return {
      imgs: [],
      vdos: [],
      isShowImageDialog: false,
      play: false,
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: ""
          }
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true
        }
      }
    };
  },
  methods: {
    clearImg() {
      this.imgs = [];
      this.vdos = [];
      this.play = false;
    },
    playurl(url) {
      this.playerOptions["sources"][0]["src"] = url;
      this.play = true;
    },
    closetv() {
      this.play = false;
    }
  }
};
</script>
 
<style>
.videolists {
  width: 120px;
  height: 120px;
  background: #333;
  cursor: pointer;
}
.demo {
  display: inline-block;
  width: 600px;
  height: 338px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  /* overflow: hidden; */
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo:hover {
  display: block;
}
.playContainer {
  position: relative;
  float: left;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
}
.playContainer div {
  position: relative;
  /* float: left; */
  border: 25px solid #404040;
  color: #404040;
  height: 0;
  width: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  margin: 30px auto;
}
.playContainer span {
  border-style: solid;
  text-indent: -9999px;
  position: absolute;
  top: -8px;
  left: -3px;
}

.playBtn span {
  border-color: transparent transparent transparent #fff;
  border-width: 8px 0 8px 12px;
  width: 0;
  height: 0;
}
.pauseBtn span {
  border-color: transparent white;
  border-width: 0 6px;
  height: 15px;
  width: 6px;
  left: -9px;
}
</style>