export default {
  // captcha: "http://public.yiniao.cc/api/v1/captcha", //测试验证码
  // requestUrl: "http://api.cc.yiniao.cc/api-v1", //测试接口地址
  captcha: "https://public.yiniao.co/api/v1/captcha", //正式验证码
  requestUrl: "https://api.cc.yiniao.co/api-v1", //正式接口地址
  checkBox1: [
    "服务特别差",
    "多次返工",
    "施工严重延期",
    "家里被弄脏",
    "师傅技能差",
    "师傅态度恶劣",
    "破坏家里财务",
    "引导走私单",
  ],
  checkBox2: [
    "服务很差",
    "细节处理不好",
    "家里被弄脏",
    "师傅不准时",
    "师傅态度恶劣",
    "师傅技术差",
    "师傅形象差",
    "有返工",
  ],
  checkBox3: [
    "服务不满意",
    "方案不专业",
    "服务装备脏",
    "家里被弄脏",
    "师傅态度差",
    "师傅技能差",
    "师傅不准时",
    "有返工",
  ],
  checkBox4: [
    "服务满意",
    "师傅态度好",
    "师傅施工专业技术好",
    "师傅准时",
    "穿工服",
    "师傅形象好，穿工服带鞋套",
  ],
  checkBox5: [
    "服务超出期待",
    "服务细心为业主考虑",
    "施工现场整洁干净",
    "师傅态度特别好",
    "师傅非常专业技术牛",
    "提前告知施工安排，不操心",
    "师傅形象好，穿工服带鞋套",
  ],
  //订单状态
  orderzhuangs: [
    {
      value: "20",
      label: "待指派",
    },
    {
      value: "1",
      label: "待接单",
    },
    {
      value: "2",
      label: "待上门",
    },
    {
      value: "3",
      label: "待签约",
    },
    {
      value: "5",
      label: "施工中",
    },
    {
      value: "7",
      label: "已完工",
    },
    {
      value: "6",
      label: "已完成",
    },
    {
      value: "8",
      label: "已取消",
    },
    {
      value: "9",
      label: "退单",
    },
    {
      value: "10",
      label: "无效订单",
    },
  ],
};
