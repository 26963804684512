import ImgPreviewComponent from './imgPreview'

const ImgPreview = {}

ImgPreview.install = Vue => {
  const ImgConstructor = Vue.extend(ImgPreviewComponent)

  const instance = new ImgConstructor()

  instance.$mount(document.createElement('div'))

  document.body.appendChild(instance.$el)

  Vue.prototype.$imgPreview = (e) => {
    instance.target = e.currentTarget
    if (e.target.innerText == "查看图片" || e.target.innerText == "") {
      instance.imgs = instance.target.getAttribute('data-img').split(',')
    } else {
      instance.vdos = instance.target.getAttribute('data-vid').split(',')
    }
    instance.isShowImageDialog = true
  }
}

export default ImgPreview